import axios from 'axios'
import config from './config'

const API_URL =  config.url + 'auth/' 

class AuthService {
  login (user) {

    return axios
      .post(API_URL + 'signin', {
        username: user.username,
        password: user.password,
      })
      .then(response => {
        if(response.data.checktoken == true){
          if (response.data.accessToken) {
            sessionStorage.setItem('user', JSON.stringify(response.data))
          }
        }else{
          return '2'
        }

        return response.data
      })
  }

  logout () {
    sessionStorage.removeItem('user')
  }

  register (user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password,
      empresa: 'Previda'
    })
  }
}

export default new AuthService()
