import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  //mode: 'history',
  base: process.env.BASE_URL,
  routes: [

   
    { 
      path: '*', 
      //component: NotFoundComponent,
      component: () => import('@/views/dashboard/Page404'),
      name: 'NotFound'
    },


   
    
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),

      //component: () => import('@/views/dashboard/Login'),
      children: [
        // Dashboard

        { 
          name: 'Controle de Acesso',
          component: () => import('@/views/dashboard/Restrito'),
          path: 'restrito'
        },

        {
          name: 'Área de Sócios',
          path: 'dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },

       
       
        {
          name: 'Register',
          path: 'register',
          component: () => import('@/views/dashboard/Register'),
        },

        {
          name: 'Upload',
          path: 'upload',
          component: () => import('@/views/dashboard/Upload'),
        },

         // Pages
         {
          name: 'Usuário',
          path: 'usuario',
          component: () => import('@/views/dashboard/Usuario'),
        },



        {
          name: 'Histórico',
          path: 'historico',
          component: () => import('@/views/dashboard/Historico'),
        },

       

    
      ],
      
      
    },
    {
      path: '/login',
      component: () => import('@/views/dashboard/Login'),
    },
  
    {
      path: '/restrito/atualizacao',
      component: () => import('@/views/restrito/Atualizacao'),
    },

  ],
})
